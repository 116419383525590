<template>
    <div>

         <v-btn   color="primary" class="ma-2 white--text"  @click="showAddBusinessSector">
            Add Business Sub-Sector <v-icon right dark> mdi-plus</v-icon>
        </v-btn>

         <v-container color="primary">
              <v-card
                    icon="clipboard-text"
                    title="Business Sub-Sector"
                    class="px-5 py-10">
                     <v-card-title>
                          <v-row>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details>
                            </v-text-field>
                        </v-row>
                        <v-row>
                            <v-progress-linear
                                :active="loading"
                                color="green"
                                :indeterminate="loading"
                                absolute
                                bottom
                                height="15">
                                Loading...
                            </v-progress-linear>
                        </v-row>
                     </v-card-title>
                      <v-data-table
                        dense
                        :headers="fields"
                        :items="items"
                        :search="search"
                        class="elevation-1">
                        <template  v-slot:[`header.id`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                            <template  v-slot:[`header.businessSectorName`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                            <template  v-slot:[`header.englishName`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                             <template  v-slot:[`header.arabicName`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>
                             <template  v-slot:[`header.Action`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                            </template>

                             <template v-slot:top>
                                  <v-dialog
                                    v-model="Editdialog"
                                    max-width="800px">
                                        <v-card>
                                             <v-card-title>
                                                <span class="text-h5">Edit Business Sub-Sector</span>
                                            </v-card-title>
                                            <v-card-text>
                                                 <v-form ref="form" v-model="valid" lazy-validation>
                                                     <v-container>
                                                         <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-select
                                                                    v-model="editedItem.businessSectorId"
                                                                    :items="options"
                                                                    :rules="[rules.required]"
                                                                    label="Business Sector"
                                                                    required
                                                                    ></v-select>
                                                            </v-col>
                                                         </v-row>
                                                          <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.englishName"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="English Name">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                         <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                 <v-text-field
                                                                    v-model="editedItem.arabicName"
                                                                    :rules="[rules.required]"
                                                                    required
                                                                    label="Arabic Name">
                                                                </v-text-field>
                                                            </v-col>
                                                         </v-row>
                                                     </v-container>
                                                 </v-form>
                                            </v-card-text>
                                             <v-card-actions>
                                                <v-spacer></v-spacer>
                                                 <v-btn color="green darken-1" text @click="close"> Cancel</v-btn>
                                                 <v-btn color="green darken-1" :disabled="!valid" text @click="validate" >Save</v-btn>
                                             </v-card-actions>
                                        </v-card>
                                  </v-dialog>
                             </template>

                            <template v-slot:[`item.Action`]="{ item }">
                                    <v-icon small class="mr-2"  @click="editItem(item)" >
                                        mdi-pencil
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="red" v-if="item.status==0" >
                                        mdi-brightness-1
                                    </v-icon>
                                     <v-icon small class="mr-2"  @click="approveRequestConfirm(item)" color="green" v-if="item.status==1" >
                                        mdi-brightness-1
                                    </v-icon>
                                    <v-icon small class="mr-2"  @click="approveDeleteConfirm(item)" color="red"  >
                                        mdi-delete-empty
                                    </v-icon>
                                </template>
                      </v-data-table>
              </v-card>
         </v-container>

          <v-dialog
        v-model="addBusinessSectorDialog"
        max-width="800px">
            <v-card>
                 <v-card-title>
                    <span class="text-h5">Add Business Sub-Sector</span>
                </v-card-title>
                <v-card-text>
                      <v-form ref="Addform" v-model="valid" lazy-validation>
                           <v-container>
                                <v-row>
                                     <v-col cols="12" sm="6" md="6">
                                            <v-select
                                            v-model="editedItem.businessSectorId"
                                            :items="options"
                                            :rules="[rules.required]"
                                            label="Business Sector"
                                            required
                                            ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                            <v-text-field
                                            v-model="editedItem.englishName"
                                            :rules="[rules.required]"
                                            required
                                            label="English Name">
                                        </v-text-field>
                                    </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                            <v-text-field
                                            v-model="editedItem.arabicName"
                                            :rules="[rules.required]"
                                            required
                                            label="Arabic Name">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                           </v-container>
                      </v-form>
                </v-card-text>
                 <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="close"> Cancel</v-btn>
                    <v-btn color="green darken-1" :disabled="!valid" text @click="validateNew" >Save</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>

         <v-dialog v-model="approveReqDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="updateSubSectorStatus">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="closeApprove">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
            </v-card>
        </v-dialog>

         <div class="text-center">
                <v-snackbar v-model="snackbar" timeout="3000">
                    {{ alertMsg }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="green"
                            text
                            v-bind="attrs"
                            @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="confirmDeleteDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteBusinessSubSector">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="confirmDeleteDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>


    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    data(){
        return{
            confirmDeleteDialog:false,
            addBusinessSectorDialog:false,
            Editdialog:false,
            valid:false,
            approveReqDialog:false,
            snackbar:false,
            search:'',
            loading:false,
            fields: [
                {value:'id', text:'Id'},
                {value:'businessSectorName', text: 'Business Sector'},
                {value:'englishName',text:'English Name'},
                {value:'arabicName', text:'Arabic Name'},
                {value:"Action", text:'Action'}
            ],
             rules: {
                required: value => !!value || 'Required Field',
            },
            items: [],
            options: [],
            selected: null,
             editedItem: {
                id:-1,
                businessSector:-1,
                arabicName: '',
                englishName:'',
                status:-1,
            },
             form: {
                englishName: null,
                arabicName:null,
                businessSector:null,
                id:null
            },
            currentEditedType:null
        }
    },
    validations: {
        form: {
            englishName: {
                required,
                minLength: minLength(3)
            },
            arabicName: {
                required,
                minLength: minLength(3)
            },
            businessSector:{
                required
            }
        }
    },
    methods:{
         approveDeleteConfirm(item){
             this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)
            this.confirmDeleteDialog = true
         },
        close () {
            this.Editdialog = false
            this.addSubSectorDialog = false;
            this.$nextTick(() => {
                this.clear();
            })
        },
        validate () {
            if(this.$refs.form.validate())
            {
                this.UpdateBusinessSector();
            }
         },
          validateNew(){
              if(this.$refs.Addform.validate())
            {
                this.addBusinessSubSector();
            }
         },
        editItem (item) {
            this.clear();
            this.getBusinessSectorOptions();
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.Editdialog = true
        },
         closeApprove(){
            this.approveReqDialog = false;
            // this.clear();
        },
         clear(){
            this.editedIndex = -1;
            this.editedItem.id = -1;
            this.editedItem.businessSector = -1;
            this.editedItem.arabicName = '';
            this.editedItem.englishName = '';
            this.editedItem.status = -1;
        },
        approveRequestConfirm(item){
            this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)
            this.approveReqDialog = true;
        },
        updateSubSectorStatus(){

            let status = -1;
            this.editedItem.status == 1 ? status = 0 : status = 1;

            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/BusinessSubSector/status?Id=${this.editedItem.id}&Status=${status}`,
                }).then(()=>{
                        this.approveReqDialog = false;
                    this.$nextTick(() => {
                                    this.items[this.editedIndex].status = status;
                                    this.alertMsg = "Business Sector updated successfully"
                                    this.snackbar = true;
                                    this.loading = false;
                             })
                });

        },
         getVariant(item,activateSector){
            if(Boolean(activateSector) == true){
                if(item.status == 1)
                    return 'primary'
                else
                    return 'secondary'
            }
            else{
                if(item.status == 1)
                    return 'secondary'
                else
                    return 'primary'
            }

        },
        getBusinessSectorOptions(){
              this.$axios({
                method: 'get',
                url: `${this.$baseURL}/BusinessSector/active`,
            })
            .then((response)=>{
                this.options = response.data.map(val=>({
                    value:val.id,
                    text:val.englishName
                })
                );
            });
         },
        showAddBusinessSector(){
            if(this.options.length == 0){
                this.getBusinessSectorOptions();
            }
           this.clear();
           this.addBusinessSectorDialog = true;
        },
        addBusinessSubSector(){

             this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/BusinessSubSector`,
                    data: {
                        EnglishName: this.editedItem.englishName,
                        ArabicName: this.editedItem.arabicName,
                        BusinessSectorId:this.editedItem.businessSectorId
                    }
                }).then((response)=>{

                    this.$nextTick(() => {
                         this.$nextTick(() => {
                                this.addBusinessSectorDialog = false;
                                this.items = response.data;
                                this.close()
                                this.alertMsg = "Business Sub-Sector added successfully"
                                this.snackbar = true;
                            });
                    });

                });

        },
        editBusinessSubSector(item,index){
              if(this.options.length == 0){
                this.getBusinessSectorOptions();
            }
             this.$nextTick(() => {
                this.form.businessSector =
                this.options.filter(o=>o.englishName ==  item.businessSectorName)[0].id
                this.form.englishName = item.englishName;
                this.form.arabicName = item.arabicName;
                this.form.id = item.id;

                this.$bvModal.show('EditBusinessSubSector');
                this.currentEditedSector = index;
            });

        },
        UpdateBusinessSector(){
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/BusinessSubSector`,
                    data: {
                        EnglishName: this.editedItem.englishName,
                        ArabicName: this.editedItem.arabicName,
                        BusinessSectorId:this.editedItem.businessSectorId,
                        id:this.editedItem.id
                    }
                }).then((response)=>{
                    if(response.data == true)
                    {
                         Object.assign(this.items[this.editedIndex], this.editedItem)
                        this.close()
                        this.alertMsg = "Business Sub-Sector updated successfully"
                        this.snackbar = true;
                    }
                });
        },
        deleteBusinessSubSector(){
            this.$axios({
                    method: 'delete',
                    url: `${this.$baseURL}/BusinessSubSector`,
                    data: {Id:this.editedItem.id}
                    }).then(()=>{
                         this.items .splice(this.editedIndex , 1);
                        this.confirmDeleteDialog = false;
                    });
        },
        onReset(){
            this.form ={
                englishName: null,
                arabicName:null,
                businessSector:null
            };
             this.$nextTick(() => {
                this.$v.$reset();
            });
        },
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
    },
    mounted(){
        this.$axios({
                method: 'get',
                url: `${this.$baseURL}/BusinessSubSector`,
            })
            .then((response)=>{
                this.items = response.data;
                console.log(response.data)
                console.log(this.items);
            });
    }
}
</script>

<style>

</style>